// navigate to next and previous item

import { Direction, getNeighbourEntryId, hasNeighbourEntry } from '../helpers/browse-view-navigator';

let itemViewerIsLoading = false;

document.addEventListener('livewire:init', () => {
    window.Livewire.on('item-viewer-navigate', handleNavigateEvent);
    window.Livewire.on('item-viewer-loaded', handleLoadedEvent);

    document.addEventListener('keydown', handleKeyEvent);
});

function handleKeyEvent(event: KeyboardEvent) {
    if (itemViewerIsNotVisible()) return;

    if (event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') return;

    // Do not navigate if lightbox is open
    if (document.querySelectorAll('.gslider').length > 0) return;

    window.Livewire.dispatch('item-viewer-navigate', {
        direction: event.key === 'ArrowRight' ? 'next' : 'previous',
        dynamicViewId: window.dynamicViewId,
    });
}

export function itemViewerIsNotVisible() {
    return document.querySelector('[data-controller="item-viewer-title"]') === null;
}

export function itemViewerIsVisible() {
    return !itemViewerIsNotVisible();
}

function handleLoadedEvent(event: { id: string }) {
    itemViewerIsLoading = false;
    const item = document.querySelector(`[id="${event.id}"]`);
    if (item === null) return;

    // Select the item
    document.querySelector('.selected')?.classList.remove('selected');
    item.classList.add('selected');

    // Check if there is a next or previous item
    const hasPrev = hasNeighbourEntry('previous');
    const hasNext = hasNeighbourEntry('next');

    // Enable or disable navigation buttons
    const btnNext = document.querySelector('#item-viewer-btn-next');
    const btnPrev = document.querySelector('#item-viewer-btn-prev');

    if (btnNext === null || btnPrev === null) return;

    toggleItemNavigationButton(btnPrev, hasPrev);
    toggleItemNavigationButton(btnNext, hasNext);
}

async function handleNavigateEvent(event: { direction: Direction, dynamicViewId?: string }) {
    if (itemViewerIsLoading) return;

    itemViewerIsLoading = true;

    const neighbourId = await getNeighbourEntryId(event.direction);

    if (neighbourId === null) {
        itemViewerIsLoading = false;
        return;
    }

    window.Livewire.dispatch('item-viewer-load', {
        id: neighbourId,
        dynamicViewId: event.dynamicViewId,
    });
}

function toggleItemNavigationButton(button: Element, exists: boolean) {
    if (exists) {
        button.removeAttribute('disabled');
        return;
    }

    button.setAttribute('disabled', 'disabled');
}
