<template>
    <div class="single-item" :class="theme">
        <div class="single-item-header">
            <img class="single-item-avatar" :src="attributes.avatar.value" alt="" />
            <div class="single-item-info overflow-hidden">
                <div class="single-item-name text-truncate">
                    {{ attributes.name.value }}
                </div>
                <div class="single-item-meta text-truncate">
                    {{ attributes.author.value }} &bull;
                    {{ attributes.manufacturer.value }} |
                    {{ attributes.era.value }} |
                    {{ attributes.scale.value }}
                </div>
            </div>
        </div>
        <div class="single-item-body">
            <div class="text-center">
                <img v-if="image" class="single-item-image" :src="image" alt="" />
            </div>
            <div class="py-5 bg-oxford-blue">
                <like :like-count="likeCount" :id="id" :like="like"></like>
            </div>
        </div>
    </div>
</template>
<script>
import Like from "../components/Like.vue";
export default {
    name: "Item",
    props: {
        id: {
            default: null,
            type: String,
        },
        attributes: {
            default: {},
            type: Object,
        },
        image: {
            default: null,
            type: String,
        },
        like: {
            default: null,
        },
        likeCount: {
            default: "0",
        },
        theme: {
            default: "opaque",
            type: String,
        },
    },
    components: {
        Like,
    },
};
</script>
