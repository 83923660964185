import Snackbar from "node-snackbar";

declare const Livewire: any;

let snackbar: undefined;

document.addEventListener('livewire:initialized', () => {
    Livewire.on('show-snackbar', (event: object) => {
        if (snackbar) {
            snackbar.close();
        }

        snackbar = Snackbar.show({
            text: event.message,
            showAction: false
        });
    });
});