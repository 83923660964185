import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

declare const Livewire: any;

document.addEventListener('livewire:init', () => {
    Livewire.on('item-viewer-loaded', () => {
        setTimeout(() => {
            GLightbox({ selector: '.g-lightbox' });
        }, 50);

        // Scroll the item viewer container to top
        const itemViewerContainer = document.querySelector('.item-viewer-container');
        if (itemViewerContainer) {
            itemViewerContainer.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    });
});
