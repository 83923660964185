const TableFilter = ((tableList) => {
    let searchInput: HTMLInputElement;

    function _removeDiacritics(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    function _onInputSearch(e: Event) {
        searchInput = e.target as HTMLInputElement;

        if (!searchInput) return;

        const dataTable = searchInput.getAttribute("data-table");

        if (!dataTable) return;

        const tables = document.getElementsByClassName(dataTable);
        tableList.forEach.call(tables, (table) => {
            tableList.forEach.call(table.tBodies, (tbody) => {
                tableList.forEach.call(tbody.rows, (row) => {
                    if (row.dataset.value === undefined) {
                        return;
                    }
                    const textContent = _removeDiacritics(row.dataset.value.toLowerCase());
                    const searchQuery = _removeDiacritics(searchInput.value.toLowerCase());
                    row.style.display =
                        textContent.indexOf(searchQuery) > -1 ? "" : "none";
                });
            });
        });
    }

    return {
        init: () => {
            const inputs = document.getElementsByClassName("filter-search");

            tableList.forEach.call(inputs, (input) => {
                input.oninput = _onInputSearch;
            });
        },
    };
})(Array.prototype);

document.addEventListener("initTableFilter", () => {
    TableFilter.init();
});

document.addEventListener("readystatechange", () => {
    if (document.readyState === "complete") {
        TableFilter.init();
    }

    // Toggle child terms with checkbox
    const termToggles = document.querySelectorAll(".terms-toggle");
    for (var i = 0; i < termToggles.length; i++) {
        termToggles[i].addEventListener("click", (e: Event) => {
            const element = e.target as HTMLInputElement;

            const termOptions = document.querySelectorAll(
                `[data-parent-term="${element.dataset.term}"]`
            ) as NodeListOf<HTMLInputElement>;

            for (var j = 0; j < termOptions.length; j++) {
                termOptions[j].checked = element.checked;
            }
        });
    }

    // Place checked items on top
    const checked = document.querySelectorAll('[data-checked="true"]');
    for (var i = 0; i < termToggles.length; i++) {
        const el = checked[i];
        if (el && el.parentNode) {

            // Create new table row
            const tr = document.createElement("tr");
            tr.innerHTML = el.innerHTML

            // Add checkbox to top
            el.parentNode.insertBefore(tr, el.parentNode.childNodes[0])

            // Remove 'old' checkbox
            el.parentNode.removeChild(el);
        }
    }
});
